/**
 * Shelf Config
 */
import cloneDeep from 'lodash/cloneDeep';
import invert from 'lodash/invert';
import range from 'lodash/range';
import sortBy from 'lodash/sortBy';
import { FurnitureConfig } from './types';
import { Option } from '../types';
import { screenFactor } from '../utils/configUtils';
import nyceConfig from './nyce-config';

const defaultColumn = {
  wallRight: { color: 'white_u11209', heightInSections: 10 },
  boards: [
    { sectionIndex: 0, color: 'white_u11209' },
    { sectionIndex: 2, color: 'white_u11209' },
    { sectionIndex: 4, color: 'white_u11209' },
    { sectionIndex: 6, color: 'white_u11209' },
    { sectionIndex: 8, color: 'white_u11209' },
    { sectionIndex: 10, color: 'white_u11209' },
  ],
  fronts: [],
  backwalls: [],
  length: 365,
  width: 342,
  heightInSections: 10,
};

const defaultFirstColumn = {
  ...defaultColumn,
  wallLeft: { color: 'white_u11209', heightInSections: 10 },
};

const laminateMapping = {
  white_9003: 'white_u11209',
  black_9004: 'black_u12001',
  light_grey_7004: 'light_grey_u12044',
  grey_7037: 'grey_u12091',
  grey_7024: 'graphite_u12233',
  green_grey_7009: 'taupe_u15133',
  stone_grey_7030: 'taupe_u15133',
  light_yellow_1016: 'yellow_u15579',
  yellow_1021: 'yellow_u15579',
  red_3016: 'terracotta_u16166',
  violet_4009: 'terracotta_u16166',
  dark_red_3005: 'terracotta_u16166',
  dark_blue_5013: 'blue_u18004',
  blue_2_5000: 'petrol_green_u18006',
  blue_5009: 'petrol_green_u18006',
  mint_6033: 'mint_green_u19006',
  dark_green_6009: 'forest_green_u19008',
  green_6029: 'forest_green_u19008',
  // Simple structure based on oak, beech is removed
  beech: 'oak',
  // glass door remova
  opaque_colorless: 'clear_colorless',
};

const metalLegsMapping = {
  aluminum_brushed: 'metal_stainless',
  aluminum_polished: 'chrome',
};

const lacquerMapping = invert(laminateMapping);

const config = {
  // TODO set to false until soUnreal supports it
  hasScene: false,
  scenesOptions: [
    {
      text: 'Natural wood',
      iconName: 'configurator/set_woodfloor.svg',
      sceneName: 'floorAndWall',
      action: 'update',
      type: 'scene',
      value: {
        wallMaterial: 'wall_set1',
        floorMaterial: 'floor_set2',
        socleMaterial: '',
      },
    },
    {
      text: 'Beton living',
      iconName: 'configurator/set_concrete.svg',
      sceneName: 'floorAndWall',
      action: 'update',
      type: 'scene',
      value: {
        wallMaterial: 'wall_set5',
        floorMaterial: 'floor_set5',
        socleMaterial: 'grey_7024',
      },
    },
    {
      text: 'Industrial loft',
      iconName: 'configurator/set_brickwall.svg',
      sceneName: 'floorAndWall',
      action: 'update',
      type: 'scene',
      value: {
        wallMaterial: 'wall_set3',
        floorMaterial: 'floor_set4',
        socleMaterial: '',
      },
    },
    {
      action: 'toggle',
      group: '',
      isDisabled: null,
      isSelected: null,
      message: '',
      type: 'scene',
      value: '',
      text: '',
      iconName: '',
    },
  ],
  backUrl: '/shelves',
  backwallPaddign: 1.9,
  boardHeight: 1.9,
  frontWidth: 1.9,
  frontPosition: 2.2, // boardHeight + firstDoorPadding
  handleHeight: 2.2,
  minBoardPosition: 0.7,
  // doors are positioned: || 3mm [ 359mm ] 2mm [ 359mm ] 3mm ||
  firstDoorPadding: 0.3,
  // to avoid light from backside
  frontsGap: 0.5,
  frontBoardGap: 2.4,
  secondDoorPadding: 0.2,
  sectionHeight: 19.2,
  minColumnWidth: 36.5,
  maxColumnWidth: 72.6,
  minColumnDepth: 34.2,
  maxColumnDepth: 47.0,
  doorWidth: 35.9,
  lengthCombos: [],
  validHeightColumn: [],

  //Glass handle positionning
  XOffsetGlassHandle: 0.1,
  YOffsetGlassHandle: -3.1,
  ZOffsetGlassHandle: 0,

  // Number of sections
  minViewFromTopHeight: 4,

  // Height Warning constants
  heightWarningLimit: 2000,
  heightWarningToAdd: 50,

  // elements types (on Element API)
  wallTypeOuter: 'outer',
  wallTypeInner: 'inner',
  boardTypeBehindFront: 'behind_front',
  boardTypeNormal: 'normal',
  boardTypeBackwall: 'backwall',
  boardTypeBackwallTop: 'top_backwall',
  boardTypeBackwallBehindFront: 'front_backwall',
  boardTypeSplittingBackwallBehindFront: 'front_between_backwall',
  boardTypeLegs: 'legs_board',
  drawerFrontType: 'drawer_module',
  doorFrontType: 'door_module',

  // values for computing width combos
  wallWidth: 1.9,
  wallBoardGap: 0.1,
  maxColumnsNumber: 11,
  maxShelfWidth: 500,

  // allowed height in section
  validColumnHeightInSections: [2, 3, 4, 6, 8, 10, 12, 13, 14, 15, 16, 17, 18],

  dimensionsFilters: ['length', 'width', 'height'],

  // const depthOptions = [0, 20, 45];
  // const heightOptions = [0, 60, 85, 100, 160];
  // New: value in cm
  baseboardCuts: {
    name: 'Baseboard cuts',
    height: {
      key: 'baseboard_height',
      min: 6.0,
      max: 16.0,
      options: [0, 6.0, 8.5, 10.0, 16.0],
    },
    depth: {
      key: 'baseboard_depth',
      min: 2.0,
      max: 4.5,
      options: [0, 2.0, 4.5],
    },
  },

  // default color for all elements on shelf
  defaultColor: 'white_u11209',
  colors: {
    aluminum: ['aluminum_brushed', 'aluminum_polished', 'matt_gold'],
    steel: ['chrome', 'metal_stainless'],
    wood: ['oak', 'walnut', 'wenge'],
    lacquered: [
      'white_9003',
      'grey_7037',
      'black_9004',
      'grey_7024',
      'yellow_1021',
      'blue_5009',
      'red_3016',
      'light_grey_7004',
      'stone_grey_7030',
      'light_yellow_1016',
      'violet_4009',
      'mint_6033',
      'green_grey_7009',
      'dark_green_6009',
      'dark_red_3005',
      'dark_blue_5013',
      'blue_2_5000',
      'green_6029',
    ],
    laminate: [
      'white_u11209',
      'light_grey_u12044',
      'grey_u12091',
      'graphite_u12233',
      'black_u12001',
      // 'burgundy_red_u17031',
      'taupe_u15133',
      'terracotta_u16166',
      'yellow_u15579',
      'mint_green_u19006',
      'forest_green_u19008',
      'petrol_green_u18006',
      'blue_u18004',
    ],
    glass: ['clear_colorless'],
    legsRoll: ['white_u11209', 'black_u12001', 'grey_u12091'],
    hanging: ['white_u11209', 'black_u12001'],
    metalBar: ['white_u11209', 'black_u12001'],
    mattGold: ['matt_gold'],
  },
  validColor: {
    board: ['laminate', 'wood'],
    metalBar: ['metalBar'],
    handle: ['laminate', 'aluminum'],
    front: ['laminate', 'wood', 'glass'],
    backwall: ['laminate', 'wood'],
    wall: ['laminate', 'wood'],
    feet: {
      socle: ['laminate', 'wood'],
      slanted: ['laminate', 'wood'],
      straight: ['laminate', 'wood'],
      square: ['laminate', 'wood', 'steel', 'mattGold'],
      roll: ['legsRoll'],
      flat: ['laminate', 'steel', 'mattGold'],
      narrow: ['laminate', 'steel', 'mattGold'],
      corner: ['laminate', 'steel', 'mattGold'],
      frame: ['laminate', 'steel', 'mattGold'],
      hairpin: ['laminate', 'steel', 'mattGold'],
      hanging: ['hanging'],
    },
    whole: ['laminate', 'wood'],
  },
  laminateMapping,
  lacquerMapping,
  metalLegsMapping,

  // heights types in a number of sections
  heightTypes: {
    backwall: {
      1: 173,
      2: 365,
      4: 750,
    },
    door: {
      2: 359,
      3: 551,
      4: 743,
      6: 1127,
      8: 1511,
      10: 1895,
      12: 2279,
    },
    drawer: {
      1: 167,
      2: 359,
    },
  },

  // height of drawer box depending on height of drawer front
  drawerBoxHeights: {
    167: 110,
    359: 220,
  },

  depthDimensions: {
    342: {
      boardDepths: {
        front_backwall: 305,
        behind_front: 318,
        default: 342,
        front_between_backwall: 317,
      },
      drawerBoxWidth: 270,
      legsSection: 'legs',
      platformDepth: 300,
      platformLength: {
        single: 60,
        double: 120,
      },
      socle: {
        depth: 264,
        profileDepth: 300,
        inner_socle: {
          narrow: 384,
          wide: 744,
        },
        outer_socle: {
          narrow: 354,
          wide: 716,
        },
      },
    },
    470: {
      boardDepths: {
        front_backwall: 433,
        behind_front: 445,
        default: 470,
        front_between_backwall: 445,
      },
      drawerBoxWidth: 390,
      legsSection: 'legs_47',
      platformDepth: 396,
      platformLength: {
        single: 74,
        double: 148,
      },
      socle: {
        depth: 392,
        profileDepth: 354,
        inner_socle: {
          narrow: 384,
          wide: 744,
        },
        outer_socle: {
          narrow: 354,
          wide: 716,
        },
      },
    },
  },

  // high wall should be split into several smaller walls
  wallHeightFoSplit: {
    // 13: [10, 3],
    2515: [1939, 576],
    // 14: [12, 2],
    2707: [2323, 384],
    // 15: [12, 3],
    2899: [2323, 576],
    // 16: [12, 2, 2],
    3091: [2323, 384, 384],
    // 17: [12, 2, 3],
    3283: [2323, 384, 576],
    // 18: [12, 3, 3],
    3475: [2323, 576, 576],
  },

  widePlatformProfilePattern: {
    section: 'legs',
    type: 'platform_profile',
    length: 726,
  },

  narrowPlatformProfilePattern: {
    section: 'legs',
    type: 'platform_profile',
    length: 332,
  },

  platformPlatePattern: {
    section: 'legs',
    type: 'platform_plate',
  },

  socleProfilePattern: {
    section: 'legs',
    type: 'socle_profile',
  },

  legTypes: {
    without_legs: {
      height: 7, // small plastic feet
      restrictions: {},
    },
    hanging: {
      height: 0,
      restrictions: {
        height: {
          min: 1,
          max: 18,
        },
        length: {
          min: 40.5,
          max: 498,
        },
      },
    },
    socle: {
      height: 60,
      restrictions: {
        coloring: 'indepedently',
        // colorWithWholeShelf: true,
        platforms: true,
        height: {
          min: 1,
          max: 18,
        },
        length: {
          min: 40.5,
          max: 498,
        },
      },
      rotation: {
        firstColumn: [
          { type: 'outer_socle', align_x: 'max', margin_x: [0, 1.1] },
        ],
        middleColumn: [{ type: 'inner_socle', margin_x: [-1, 0] }],
        lastColumn: [{ type: 'outer_socle', margin_x: [-1, 0] }],
        singleColumn: [{ type: 'inner_socle', align_x: 'center' }],
      },
    },
    square: {
      height: 20,
      restrictions: {
        coloring: 'all_at_once',
        // colorWithWholeShelf: true,
        platforms: false,
        height: {
          min: 1,
          max: 18,
        },
        length: {
          min: 40.5,
          max: 498,
        },
      },
      rotation: {
        firstColumn: [{}, { align_y: 'max', rotation_z: -90 }],
        lastColumn: [
          { align_x: 'max', align_y: 'max', rotation_z: 180 },
          { align_x: 'max', rotation_z: 90 },
        ],
        singleColumn: [
          {},
          { align_y: 'max', rotation_z: -90 },
          { align_x: 'max', align_y: 'max', rotation_z: 180 },
          { align_x: 'max', rotation_z: 90 },
        ],
      },
    },
    straight: {
      height: 120,
      restrictions: {
        coloring: 'all_at_once',
        // colorWithWholeShelf: true,
        platforms: true,
        height: {
          min: 1,
          max: 8,
        },
        length: {
          min: 40.5,
          max: 233,
        },
      },
      JSXPropsForAllRotations: {
        margin_x: 3,
        margin_y: 6,
      },
      rotation: {
        firstColumn: [{}, { align_y: 'max', rotation_z: -90 }],
        lastColumn: [
          { align_x: 'max', align_y: 'max', rotation_z: 180 },
          { align_x: 'max', rotation_z: 90 },
        ],
        singleColumn: [
          {},
          { align_y: 'max', rotation_z: -90 },
          { align_x: 'max', align_y: 'max', rotation_z: 180 },
          { align_x: 'max', rotation_z: 90 },
        ],
      },
    },
    slanted: {
      height: 120,
      restrictions: {
        coloring: 'all_at_once',
        // colorWithWholeShelf: true,
        platforms: true,
        height: {
          min: 1,
          max: 8,
        },
        length: {
          min: 40.5,
          max: 233,
        },
      },
      JSXPropsForAllRotations: {
        margin_x: 7.2,
        margin_y: 6,
      },
      rotation: {
        firstColumn: [{}, { align_y: 'max', rotation_z: -90 }],
        lastColumn: [
          { align_x: 'max', align_y: 'max', rotation_z: 180 },
          { align_x: 'max', rotation_z: 90 },
        ],
        singleColumn: [
          {},
          { align_y: 'max', rotation_z: -90 },
          { align_x: 'max', align_y: 'max', rotation_z: 180 },
          { align_x: 'max', rotation_z: 90 },
        ],
      },
    },
    flat: {
      height: 120,
      restrictions: {
        coloring: 'all_at_once',
        // colorWithWholeShelf: true,
        platforms: true,
        height: {
          min: 1,
          max: 8,
        },
        length: {
          min: 40.5,
          max: 233,
        },
      },
      JSXPropsForAllRotations: {
        margin_x: 0,
        margin_y: 0,
      },
      rotation: {
        firstColumn: [
          { type: 'flat_left' },
          { type: 'flat_right', align_y: 'max', rotation_z: 180 },
        ],
        lastColumn: [
          { type: 'flat_right', align_x: 'max' },
          {
            type: 'flat_left',
            align_x: 'max',
            rotation_z: 180,
            align_y: 'max',
          },
        ],
        singleColumn: [
          { type: 'flat_left' },
          { type: 'flat_right', align_y: 'max', rotation_z: 180 },
          { type: 'flat_right', align_x: 'max' },
          {
            type: 'flat_left',
            align_x: 'max',
            rotation_z: 180,
            align_y: 'max',
          },
        ],
      },
    },
    narrow: {
      height: 120,
      restrictions: {
        coloring: 'all_at_once',
        // colorWithWholeShelf: true,
        platforms: true,
        height: {
          min: 1,
          max: 8,
        },
        length: {
          min: 40.5,
          max: 233,
        },
      },
      JSXPropsForAllRotations: {
        margin_x: 0,
        margin_y: 0,
      },
      rotation: {
        firstColumn: [
          { type: 'narrow_left' },
          { type: 'narrow_right', align_y: 'max', rotation_z: 180 },
        ],
        lastColumn: [
          { type: 'narrow_right', align_x: 'max' },
          {
            type: 'narrow_left',
            align_x: 'max',
            rotation_z: 180,
            align_y: 'max',
          },
        ],
        singleColumn: [
          { type: 'narrow_left' },
          { type: 'narrow_right', align_y: 'max', rotation_z: 180 },
          { type: 'narrow_right', align_x: 'max' },
          {
            type: 'narrow_left',
            align_x: 'max',
            rotation_z: 180,
            align_y: 'max',
          },
        ],
      },
    },
    corner: {
      height: 120,
      restrictions: {
        coloring: 'all_at_once',
        // colorWithWholeShelf: true,
        platforms: true,
        height: {
          min: 1,
          max: 8,
        },
        length: {
          min: 40.5,
          max: 233,
        },
      },
      JSXPropsForAllRotations: {
        margin_x: 0,
        margin_y: 0,
      },
      rotation: {
        firstColumn: [
          { type: 'corner_left' },
          { type: 'corner_right', align_y: 'max', rotation_z: 180 },
        ],
        lastColumn: [
          { type: 'corner_right', align_x: 'max' },
          {
            type: 'corner_left',
            align_x: 'max',
            rotation_z: 180,
            align_y: 'max',
          },
        ],
        singleColumn: [
          { type: 'corner_left' },
          { type: 'corner_right', align_y: 'max', rotation_z: 180 },
          { type: 'corner_right', align_x: 'max' },
          {
            type: 'corner_left',
            align_x: 'max',
            rotation_z: 180,
            align_y: 'max',
          },
        ],
      },
    },
    frame: {
      height: 120,
      width: {
        342: 340,
        470: 468,
      },
      restrictions: {
        coloring: 'all_at_once',
        // colorWithWholeShelf: true,
        platforms: true,
        height: {
          min: 1,
          max: 8,
        },
        length: {
          min: 40.5,
          max: 233,
        },
      },
      JSXPropsForAllRotations: {
        margin_x: 0,
        margin_y: 0,
      },
      rotation: {
        firstColumn: [{ type: 'frame_side' }],
        lastColumn: [{ type: 'frame_side', align_x: 'max', rotation_z: 180 }],
        singleColumn: [
          { type: 'frame_side' },
          { type: 'frame_side', align_x: 'max', rotation_z: 180 },
        ],
      },
    },
    hairpin: {
      height: 120, //in the sheet it's 121 but we use this value only for display
      restrictions: {
        coloring: 'all_at_once',
        // colorWithWholeShelf: true,
        platforms: true,
        height: {
          min: 1,
          max: 8,
        },
        length: {
          min: 40.5,
          max: 233,
        },
      },
      JSXPropsForAllRotations: {
        margin_x: 0.73,
        margin_y: 0.73,
      },
      rotation: {
        firstColumn: [
          { type: 'hairpin_leg' },
          { type: 'hairpin_leg', align_y: 'max', rotation_z: -90 },
        ],
        lastColumn: [
          { type: 'hairpin_leg', align_x: 'max', rotation_z: 90 },
          {
            type: 'hairpin_leg',
            align_x: 'max',
            rotation_z: 180,
            align_y: 'max',
          },
        ],
        singleColumn: [
          { type: 'hairpin_leg' },
          { type: 'hairpin_leg', align_y: 'max', rotation_z: -90 },
          { type: 'hairpin_leg', align_x: 'max', rotation_z: 90 },
          {
            type: 'hairpin_leg',
            align_x: 'max',
            rotation_z: 180,
            align_y: 'max',
          },
        ],
      },
    },
    roll: {
      height: 80,
      restrictions: {
        coloring: 'all_at_once',
        // colorWithWholeShelf: false,
        platforms: true,
        height: {
          min: 1,
          max: 4,
        },
        length: {
          min: 40.5,
          max: 157,
        },
      },
      rotation: {
        firstColumn: [
          { rotation_z: 59.4, margin_x: 1.5 },
          { align_y: 'max', rotation_z: 149.4 },
        ],
        middleColumn: [
          // Shifted with margin_x to the half of the leg length
          { align_y: 'center', margin_x: -2.5, rotation_z: -59.5 },
        ],
        lastColumn: [
          { align_x: 'max', rotation_z: -59.5 },
          { align_x: 'max', align_y: 'max', rotation_z: -149.4 },
          { align_y: 'center', rotation_z: -59.5 },
        ],
        singleColumn: [
          { rotation_z: 59.4 },
          { align_y: 'max', rotation_z: 149.4 },
          { align_x: 'max', rotation_z: -59.5 },
          { align_x: 'max', align_y: 'max', rotation_z: -149.4 },
        ],
      },
      JSXPropsForAllRotations: {
        margin_x: 1.5,
        margin_y: 1.5,
      },
    },
    support: {
      width: 25,
    },
    square_support: {
      width: 100,
    },
  },
  nyce: {
    animation: {
      camera: {
        fov: 60,
        angle: 0,
        vAngle: 0,
        coreAngle: 0,
        coreVAngle: 0,
        autozoom: true,
        bboxScalingFactor: [1, 1, 1, 1, 1, 1],
        ...screenFactor(1200, 700, 133, 20, 203, 203),
      },
      processing: {
        noise: 0.1,
        samples: 200,
        no_intermediate: true,
        floor: [0.8863, 0.8902, 0.898],
        floor_luminance: 0.75,
        srgb: true,
        ...nyceConfig.presets.shelf,
      },
    },
    normal: {
      camera: {
        fov: 60,
        angle: 0,
        vAngle: 0,
        coreAngle: 0,
        coreVAngle: 0,
        autozoom: true,
        bboxScalingFactor: [1, 1, 1, 1, 1, 1],
        ...screenFactor(1500, 875, 166.25, 29.17, 253.75, 253.75),
      },
      processing: {
        noise: 0.1,
        samples: 500,
        no_intermediate: false,
        floor: [0.8863, 0.8902, 0.898],
        floor_luminance: 0.75,
        srgb: true,
        ...nyceConfig.presets.shelf,
      },
    },
    mobile: {
      camera: {
        fov: 60,
        angle: 0,
        vAngle: 0,
        coreAngle: 0,
        coreVAngle: 0,
        autozoom: true,
        bboxScalingFactor: [1, 1, 1, 1, 1, 1],
        ...screenFactor(800, 900, 50, 150, 80, 80),
      },
      processing: {
        noise: 0.1,
        samples: 500,
        no_intermediate: false,
        floor: [0.8863, 0.8902, 0.898],
        floor_luminance: 0.75,
        srgb: true,
        ...nyceConfig.presets.shelf,
      },
    },
    hires: {
      camera: {
        fov: 40,
        angle: 30,
        vAngle: 0,
        coreAngle: 0,
        coreVAngle: -4,
        autozoom: true,
        bboxScalingFactor: [1.2, 1.2, 1.2, 1.2, 1.2, 1.2],
        ...screenFactor(1440, 1440, 0, 0, 0, 0),
      },
      processing: {
        noise: 0.1,
        samples: 700,
        no_intermediate: true,
        floor: [1.0, 1.0, 1.0],
        floor_luminance: 0.75,
        srgb: true,
        ...nyceConfig.presets.shelf,
      },
    },
    thumbnail: {
      camera: {
        height: 74,
        width: 74,
        fov: 40,
        angle: 30,
        vAngle: 0,
        coreAngle: 0,
        coreVAngle: -4,
        autozoom: true,
        bboxScalingFactor: [1.2, 1.2, 1.2, 1.2, 1.2, 1.2],
        ...screenFactor(1440, 1440, 0, 0, 0, 0),
      },
      processing: {
        noise: 0.1,
        samples: 700,
        no_intermediate: true,
        floor: [1.0, 1.0, 1.0],
        floor_luminance: 0.75,
        srgb: true,
        ...nyceConfig.presets.shelf,
      },
    },
  },
};

config.lengthCombos = (() => {
  let length;
  const minColumnWidth = Math.floor(10 * config.minColumnWidth);
  const maxColumnWidth = Math.floor(10 * config.maxColumnWidth);
  const maxShelfWidth = Math.floor(10 * config.maxShelfWidth);
  const wallWidth = Math.floor(10 * config.wallWidth);
  const wallBoardGap = Math.floor(10 * config.wallBoardGap);
  const wallWidthInclBoardGaps = wallWidth + wallBoardGap;
  let combos = [];
  const nums = range(0, config.maxColumnsNumber + 1);

  for (const i of nums) {
    for (const j of nums) {
      length =
        i * minColumnWidth +
        j * maxColumnWidth +
        (i + j + 1) * wallWidthInclBoardGaps;
      combos.push({ length, narrow: i, wide: j });
    }
  }

  combos = combos.filter(
    (combo) => minColumnWidth < combo.length && combo.length < maxShelfWidth
  );
  return sortBy(combos, 'length');
})();

config.validHeightColumn = config.validColumnHeightInSections.map(
  (h) =>
    h * Math.floor(config.sectionHeight * 10) +
    Math.floor(config.boardHeight * 10)
);

const shelfConfig: ShelfFurnitureConfig = {
  ...config,
  stage: 'default',
  label: 'SVG',
  hasAssembly: true,
  hasViewInterior: true,
  showRelatedProduct: true,
  noBackgroundCarousel: false,
  translationDimensionMapping: true,
  furnitureType: 'shelf',
  invalidUuidFallback: 'FHVFfUw7',
  glassFrontHingesPosition: {
    359: [67.5],
    551: [67.5],
    743: [67.5],
    1127: [67.5],
    1511: [67.5, 416],
    1895: [67.5, 608],
    2279: [67.5, 608],
  },
  hingeSize: 50,
  // number of sections in heightTypes
  numberSections: {
    backwall: invert(config.heightTypes.backwall),
    door: invert(config.heightTypes.door),
    drawer: invert(config.heightTypes.drawer),
  },
  metalBarLengthMapping: {
    726: 724,
    365: 360,
  },
  reinforcedBoardSuffix: '_reinforced',
  tabs: {
    desktop: ['default'],
    mobile: ['elements', 'colorsMobile', 'review'],
  },
  tabsNextText: [
    'To styles',
    'To dimensions',
    'To boards',
    'To fronts',
    'To backwalls',
    'To colors',
    'To review',
  ],
  assemblyFeature: 14,
  baseBoardCutsFeature: 15,
  productDetailText: 'About Our Shelves',
  default: {
    columns: [
      cloneDeep(defaultFirstColumn),
      cloneDeep(defaultColumn),
      cloneDeep(defaultColumn),
      cloneDeep(defaultColumn),
      cloneDeep(defaultColumn),
    ],
  },
  options: [
    /* Hanging shelf */
    {
      action: 'remove',
      group: 'style',
      isDisabled: null,
      isSelected: null,
      message: '',
      type: 'style',
      value: 'standing',
      text: 'stand',
      iconName: 'configurator/style-stand.svg',
    },
    {
      action: 'update',
      group: 'style',
      isDisabled: null,
      isSelected: null,
      message: '',
      type: 'style',
      value: 'hanging high',
      text: 'hanging high',
      iconName: 'configurator/style-high.svg',
    },
    /* Legs */
    {
      action: 'remove',
      group: 'dimension',
      isDisabled: null,
      isSelected: null,
      message: '',
      type: 'feet',
      value: 'without_legs',
      text: 'Without legs',
      iconName: 'configurator/shelf/legs-nolegs.svg',
    },
    {
      action: 'update',
      group: 'dimension',
      isDisabled: null,
      isSelected: null,
      message: '',
      type: 'feet',
      value: 'socle',
      text: 'Socle',
      iconName: 'configurator/shelf/legs-socles.svg',
    },
    {
      action: 'update',
      group: 'dimension',
      isDisabled: null,
      isSelected: null,
      message: '',
      type: 'feet',
      value: 'square',
      text: 'Bricks',
      iconName: 'configurator/shelf/legs-bricks.svg',
    },
    {
      action: 'update',
      group: 'dimension',
      isDisabled: null,
      isSelected: null,
      message: '',
      type: 'feet',
      value: 'straight',
      text: 'Straight',
      iconName: 'configurator/shelf/legs-straight.svg',
    },
    {
      action: 'update',
      group: 'dimension',
      isDisabled: null,
      isSelected: null,
      message: '',
      type: 'feet',
      value: 'slanted',
      text: 'Slanted',
      iconName: 'configurator/shelf/legs-slanted.svg',
    },
    {
      action: 'update',
      group: 'dimension',
      isDisabled: null,
      isSelected: null,
      message: '',
      type: 'feet',
      value: 'roll',
      text: 'Rolls',
      iconName: 'configurator/shelf/legs-rolls.svg',
    },
    {
      action: 'update',
      group: 'dimension',
      isDisabled: null,
      isSelected: null,
      message: '',
      type: 'feet',
      value: 'narrow',
      text: 'Narrow_Leg',
      iconName: 'configurator/shelf/legs-narrow.svg',
    },
    {
      action: 'update',
      group: 'dimension',
      isDisabled: null,
      isSelected: null,
      message: '',
      type: 'feet',
      value: 'flat',
      text: 'Flat_Leg',
      iconName: 'configurator/shelf/legs-flat.svg',
    },
    {
      action: 'update',
      group: 'dimension',
      isDisabled: null,
      isSelected: null,
      message: '',
      type: 'feet',
      value: 'frame',
      text: 'Frame_Leg',
      iconName: 'configurator/shelf/legs-frame.svg',
    },
    {
      action: 'update',
      group: 'dimension',
      isDisabled: null,
      isSelected: null,
      message: '',
      type: 'feet',
      value: 'corner',
      text: 'Corner_Leg',
      iconName: 'configurator/shelf/legs-corner.svg',
    },
    {
      action: 'update',
      group: 'dimension',
      isDisabled: null,
      isSelected: null,
      message: '',
      type: 'feet',
      value: 'hairpin',
      text: 'Hairpin',
      iconName: 'configurator/shelf/legs-hairpin.svg',
    },
    /* Dimensions */
    ...config.lengthCombos.map((value) => {
      return {
        action: 'update',
        group: 'dimension',
        isDisabled: null,
        isSelected: null,
        message: '',
        type: 'length',
        value: value.length,
        text: '',
        iconName: '',
      };
    }),
    ...config.validHeightColumn.map((value) => {
      return {
        action: 'update',
        group: 'dimension',
        iconName: '',
        isDisabled: null,
        isSelected: null,
        message: '',
        type: 'height',
        value,
        text: '',
      };
    }),
    {
      action: 'update',
      group: 'dimension',
      iconName: 'configurator/depth-35',
      isDisabled: null,
      isSelected: null,
      message: '',
      type: 'width',
      value: config.minColumnDepth * 10,
      text: '' + config.minColumnDepth,
    },
    {
      action: 'update',
      group: 'dimension',
      iconName: 'configurator/depth-47',
      isDisabled: null,
      isSelected: null,
      message: '',
      type: 'width',
      value: config.maxColumnDepth * 10,
      text: '' + config.maxColumnDepth,
    },
    /* Columns */
    ...config.validHeightColumn.map((value) => {
      return {
        action: 'update',
        group: 'column',
        iconName: '',
        isDisabled: null,
        isSelected: null,
        message: '',
        type: 'height',
        value,
        text: '',
      };
    }),
    {
      action: 'update',
      group: 'column',
      iconName: 'configurator/column-sm',
      isDisabled: null,
      isSelected: null,
      message: '',
      type: 'length',
      value: config.minColumnWidth * 10,
      text: '',
    },
    {
      action: 'update',
      group: 'column',
      iconName: 'configurator/column-lg',
      isDisabled: null,
      isSelected: null,
      message: '',
      type: 'length',
      value: config.maxColumnWidth * 10,
      text: '',
    },
    {
      action: 'duplicate',
      group: 'column',
      isDisabled: null,
      isSelected: null,
      message: '',
      type: 'column',
      value: '',
      text: 'Duplicate',
      iconName: 'configurator/duplicate',
    },
    {
      action: 'remove',
      group: 'column',
      isDisabled: null,
      isSelected: null,
      message: '',
      type: 'column',
      value: '',
      text: 'Remove',
      iconName: 'configurator/trash-bin',
    },
    /* Boards */
    {
      action: 'update',
      group: 'board',
      isDisabled: null,
      isSelected: null,
      message: '',
      type: 'board',
      value: 'board',
      text: 'Standard boards',
      iconName: 'configurator/shelf/boards_normal',
    },
    {
      action: 'update',
      group: 'board',
      isDisabled: null,
      isSelected: null,
      message: '',
      type: 'metalBar',
      value: 'metalBar',
      text: 'Heavy duty boards',
      iconName: 'configurator/shelf/boards_heavy',
    },
    {
      action: 'remove',
      group: 'board',
      isDisabled: null,
      isSelected: null,
      message: '',
      type: 'board',
      value: '',
      text: 'Remove Board',
      iconName: 'configurator/trash-bin',
    },
    /* Fronts */
    {
      action: 'update',
      group: 'front',
      isDisabled: null,
      isSelected: null,
      message: '',
      type: 'front',
      value: {
        type: 'drawer',
        height: 1,
      },
      text: 'Drawer 17 cm',
      iconName: 'configurator/shelf/drawer-17',
    },
    {
      action: 'update',
      group: 'front',
      isDisabled: null,
      isSelected: null,
      message: '',
      type: 'front',
      value: {
        type: 'drawer',
        height: 2,
      },
      text: 'Drawer 36 cm',
      iconName: 'configurator/shelf/drawer-36',
    },
    {
      action: 'update',
      group: 'front',
      isDisabled: null,
      isSelected: null,
      message: '',
      type: 'front',
      value: {
        type: 'door',
        height: 2,
      },
      text: 'Low door 36 cm',
      iconName: 'configurator/shelf/door-low-36',
    },
    {
      action: 'update',
      group: 'front',
      isDisabled: null,
      isSelected: null,
      message: '',
      type: 'front',
      value: {
        type: 'door',
        height: 3,
      },
      text: 'Low door 55 cm',
      iconName: 'configurator/shelf/door-low-55',
    },
    {
      action: 'update',
      group: 'front',
      isDisabled: null,
      isSelected: null,
      message: '',
      type: 'front',
      value: {
        type: 'door',
        height: 4,
      },
      text: 'High door 74 cm',
      iconName: 'configurator/shelf/door-high-75',
    },
    {
      action: 'update',
      group: 'front',
      isDisabled: null,
      isSelected: null,
      message: '',
      type: 'front',
      value: {
        type: 'door',
        height: 6,
      },
      text: 'High door 113 cm',
      iconName: 'configurator/shelf/door-high-113',
    },
    {
      action: 'update',
      group: 'front',
      isDisabled: null,
      isSelected: null,
      message: '',
      type: 'front',
      value: {
        type: 'door',
        height: 8,
      },
      text: 'High door 151 cm',
      iconName: 'configurator/shelf/door-high-150',
    },
    {
      action: 'update',
      group: 'front',
      isDisabled: null,
      isSelected: null,
      message: '',
      type: 'front',
      value: {
        type: 'door',
        height: 10,
      },
      text: 'High door 190 cm',
      iconName: 'configurator/shelf/door-high-150',
    },
    {
      action: 'update',
      group: 'front',
      isDisabled: null,
      isSelected: null,
      message: '',
      type: 'front',
      value: {
        type: 'door',
        height: 12,
      },
      text: 'High door 228 cm',
      iconName: 'configurator/shelf/door-high-150',
    },
    {
      action: 'remove',
      group: 'front',
      isDisabled: null,
      isSelected: null,
      message: '',
      type: 'front',
      value: '',
      text: 'Remove Front',
      iconName: 'configurator/trash-bin',
    },
    /* Handles */
    {
      action: 'update',
      group: 'handle',
      isDisabled: null,
      isSelected: null,
      message: '',
      type: 'handle',
      value: '',
      text: 'Handle',
      iconName: 'configurator/shelf/handles',
    },
    {
      action: 'remove',
      group: 'handle',
      isDisabled: null,
      isSelected: null,
      message: '',
      type: 'handle',
      value: '',
      text: 'Push to open',
      iconName: 'configurator/shelf/push-to-open',
    },
    /* Backwalls */
    {
      action: 'update',
      type: 'backwall',
      isDisabled: null,
      isSelected: null,
      message: '',
      group: 'backwallPartial',
      value: '',
      text: 'Individually',
      iconName: 'configurator/shelf/backwalls-custom',
    },
    {
      action: 'update',
      type: 'all_backwall',
      group: 'backwallAll',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      text: 'Everywhere',
      iconName: 'configurator/shelf/backwalls-full',
    },
    {
      action: 'remove',
      type: 'all_backwall',
      group: 'backwallAll',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      text: 'Remove',
      iconName: 'configurator/shelf/backwalls-none',
    },
    /* Colors */
    ...[...config.colors.laminate].map((color) => {
      return {
        action: 'update',
        group: 'color',
        isDisabled: null,
        isSelected: null,
        message: '',
        type: 'color',
        text: '',
        value: color,
        iconName: `materials/${color}.png`,
        categoryName: 'varnish',
      };
    }),
    ...[...config.colors.wood].map((color) => {
      return {
        action: 'update',
        group: 'color',
        isDisabled: null,
        isSelected: null,
        message: '',
        type: 'color',
        text: '',
        value: color,
        iconName: `materials/${color}.png`,
        categoryName: 'wood',
      };
    }),
    ...[...config.colors.glass].map((color) => {
      return {
        action: 'update',
        group: 'color',
        isDisabled: null,
        isSelected: null,
        message: '',
        type: 'color',
        text: '',
        value: color,
        iconName: `materials/${color}.png`,
        categoryName: 'glass',
      };
    }),
    ...[...config.colors.steel].map((color) => {
      return {
        action: 'update',
        group: 'color',
        isDisabled: null,
        isSelected: null,
        message: '',
        type: 'color',
        text: '',
        value: color,
        iconName: `materials/${color}.png`,
        categoryName: 'steel',
      };
    }),
    ...[...config.colors.aluminum].map((color) => {
      return {
        action: 'update',
        group: 'color',
        isDisabled: null,
        isSelected: null,
        message: '',
        type: 'color',
        text: '',
        value: color,
        iconName: `materials/${color}.png`,
        categoryName: 'steel',
      };
    }),
  ],
  viewModes: {
    styles: {
      zoomMax: 1,
      subTabsName: [],
      text: '',
      iconName: '',
      selectionMode: 'none',
      resetSelection: false,
      group: '',
      groupStructure: 'style',
      camera: {
        low: { angle: 0, vAngle: 0 },
        high: { angle: 0, vAngle: 0 },
      },
      labelMode: '',
    },
    'dimensions-legs': {
      zoomMax: 1,
      subTabsName: [],
      text: '',
      iconName: '',
      selectionMode: 'single',
      resetSelection: true,
      group: 'column',
      groupStructure: 'dimension',
      camera: {
        low: { angle: 0, vAngle: 0 },
        high: { angle: 0, vAngle: 0 },
      },
      labelMode: 'columns',
    },
    legs: {
      zoomMax: 1,
      subTabsName: [],
      text: '',
      iconName: '',
      selectionMode: 'none',
      resetSelection: true,
      group: '',
      groupStructure: 'dimension',
      camera: {
        low: { angle: 0, vAngle: 0 },
        high: { angle: 0, vAngle: 0 },
      },
      labelMode: '',
    },
    elements: {
      zoomMax: 1.8,
      subTabsName: [
        'width',
        'height',
        'depth',
        'styles',
        'dimensions-legs',
        'legs',
        'boards',
        'fronts',
        'backwalls',
        'baseboard-cuts',
      ],
      defaultSubTabName: 'width',
      text: '',
      iconName: '',
      selectionMode: 'none',
      resetSelection: false,
      group: '',
      groupStructure: 'dimension',
      camera: {
        low: { angle: 0, vAngle: 0 },
        high: { angle: 0, vAngle: 0 },
      },
      labelMode: 'columns',
    },
    width: {
      zoomMax: 1,
      subTabsName: [],
      text: '',
      iconName: '',
      selectionMode: 'none',
      resetSelection: false,
      group: '',
      groupStructure: 'dimension',
      camera: {
        low: { angle: 0, vAngle: 0 },
        high: { angle: 0, vAngle: 0 },
      },
      labelMode: '',
    },
    height: {
      zoomMax: 1,
      subTabsName: [],
      text: '',
      iconName: '',
      selectionMode: 'none',
      resetSelection: false,
      group: '',
      groupStructure: 'dimension',
      camera: {
        low: { angle: 0, vAngle: 0 },
        high: { angle: 0, vAngle: 0 },
      },
      labelMode: '',
    },
    depth: {
      zoomMax: 1,
      subTabsName: [],
      text: '',
      iconName: '',
      selectionMode: 'none',
      resetSelection: false,
      group: '',
      groupStructure: 'dimension',
      camera: {
        low: { angle: 0, vAngle: 0 },
        high: { angle: 0, vAngle: 0 },
      },
      labelMode: '',
    },
    boards: {
      zoomMax: 1,
      subTabsName: ['addBoards, deleteBoards'],
      text: '',
      iconName: '',
      selectionMode: 'none',
      resetSelection: true,
      group: '',
      groupStructure: '',
      camera: {
        low: { angle: 0, vAngle: 0 },
        high: { angle: 0, vAngle: 0 },
      },
      labelMode: '',
    },
    addBoards: {
      zoomMax: 1,
      subTabsName: [],
      text: '',
      iconName: '',
      selectionMode: 'multi',
      resetSelection: true,
      group: 'board',
      groupStructure: '',
      camera: {
        low: { angle: 0, vAngle: 0 },
        high: { angle: 0, vAngle: 0 },
      },
      labelMode: 'all_boards',
    },
    deleteBoards: {
      zoomMax: 1,
      subTabsName: [],
      text: '',
      iconName: '',
      selectionMode: 'multi',
      resetSelection: true,
      group: 'board',
      groupStructure: '',
      camera: {
        low: { angle: 0, vAngle: 0 },
        high: { angle: 0, vAngle: 0 },
      },
      labelMode: 'existing_boards',
    },
    fronts: {
      zoomMax: 1,
      subTabsName: [
        'addFronts',
        'deleteFronts',
        'initHandles',
        'changeHandles',
      ],
      text: '',
      iconName: '',
      selectionMode: 'none',
      resetSelection: true,
      group: '',
      groupStructure: '',
      camera: {
        low: { angle: 0, vAngle: 0 },
        high: { angle: 0, vAngle: 0 },
      },
      labelMode: '',
    },
    addFronts: {
      zoomMax: 1,
      subTabsName: [],
      text: '',
      iconName: '',
      selectionMode: 'multi',
      resetSelection: true,
      group: 'front',
      groupStructure: '',
      camera: {
        low: { angle: 0, vAngle: 0 },
        high: { angle: 0, vAngle: 0 },
      },
      labelMode: 'all_fronts',
    },
    deleteFronts: {
      zoomMax: 1,
      subTabsName: [],
      text: '',
      iconName: '',
      selectionMode: 'multi',
      resetSelection: true,
      group: 'front',
      groupStructure: '',
      camera: {
        low: { angle: 0, vAngle: 0 },
        high: { angle: 0, vAngle: 0 },
      },
      labelMode: 'fronts',
    },
    initHandles: {
      zoomMax: 1,
      subTabsName: [],
      text: '',
      iconName: 'fronts',
      selectionMode: 'multi',
      resetSelection: true,
      group: '',
      groupStructure: '',
      camera: {
        low: { angle: 0, vAngle: 0 },
        high: { angle: 0, vAngle: 0 },
      },
      labelMode: '',
    },
    changeHandles: {
      zoomMax: 1,
      subTabsName: [],
      text: '',
      iconName: 'fronts',
      selectionMode: 'multi',
      resetSelection: true,
      group: 'handle',
      groupStructure: '',
      camera: {
        low: { angle: 0, vAngle: 0 },
        high: { angle: 0, vAngle: 0 },
      },
      labelMode: 'handle',
    },
    backwalls: {
      zoomMax: 1,
      subTabsName: ['backwalls', 'backwallsPartial'],
      text: '',
      iconName: '',
      selectionMode: 'none',
      resetSelection: true,
      group: '',
      groupStructure: 'backwallAll',
      camera: {
        low: { angle: 0, vAngle: 0 },
        high: { angle: 0, vAngle: 0 },
      },
      labelMode: 'backwalls',
    },
    backwallsPartial: {
      zoomMax: 1,
      subTabsName: [],
      text: '',
      iconName: '',
      selectionMode: 'multi',
      resetSelection: true,
      group: 'backwallPartial',
      groupStructure: 'backwallAll',
      camera: {
        low: { angle: 0, vAngle: 0 },
        high: { angle: 0, vAngle: 0 },
      },
      labelMode: 'backwalls',
    },
    'baseboard-cuts': {
      zoomMax: 1.8,
      subTabsName: [],
      text: '',
      iconName: '',
      selectionMode: 'none',
      resetSelection: true,
      group: '',
      groupStructure: '',
      camera: {
        low: { angle: 0, vAngle: 0 },
        high: { angle: 0, vAngle: 0 },
      },
      labelMode: '',
    },
    colorsBoards: {
      zoomMax: 1,
      camera: {
        low: { angle: 0, vAngle: 0 },
        high: { angle: 0, vAngle: 0 },
      },
      group: 'color',
      groupStructure: '',
      iconName: 'configurator/shelf/parts-boards',
      labelMode: 'color_boards',
      selectionMode: 'multi',
      resetSelection: true,
      subTabsName: [],
      text: 'Boards',
    },
    colorsMetalBars: {
      zoomMax: 1,
      camera: {
        low: { angle: 0, vAngle: 37 },
        high: { angle: 0, vAngle: 37 },
      },
      group: 'color',
      groupStructure: '',
      iconName: 'configurator/shelf/parts-metal-bar',
      labelMode: 'color_metal_bars',
      selectionMode: 'multi',
      resetSelection: true,
      subTabsName: [],
      text: 'Metal Bar',
    },
    colorsWalls: {
      zoomMax: 1,
      camera: {
        low: { angle: 0, vAngle: 0 },
        high: { angle: 0, vAngle: 0 },
      },
      group: 'color',
      groupStructure: '',
      iconName: 'configurator/shelf/parts-walls',
      labelMode: 'color_walls',
      selectionMode: 'multi',
      resetSelection: true,
      subTabsName: [],
      text: 'Walls',
    },
    colorsFronts: {
      zoomMax: 1,
      camera: {
        low: { angle: 0, vAngle: 0 },
        high: { angle: 0, vAngle: 0 },
      },
      group: 'color',
      groupStructure: '',
      iconName: 'configurator/shelf/parts-fronts',
      labelMode: 'color_fronts',
      selectionMode: 'multi',
      resetSelection: true,
      subTabsName: [],
      text: 'Fronts',
    },
    colorsHandles: {
      zoomMax: 1,
      camera: {
        low: { angle: 0, vAngle: 0 },
        high: { angle: 0, vAngle: 0 },
      },
      group: 'color',
      groupStructure: '',
      iconName: 'configurator/shelf/parts-handles',
      labelMode: 'color_handles',
      selectionMode: 'multi',
      resetSelection: true,
      subTabsName: [],
      text: 'Handles',
    },
    colorsBackwalls: {
      zoomMax: 1,
      camera: {
        low: { angle: 0, vAngle: 0 },
        high: { angle: 0, vAngle: 0 },
      },
      group: 'color',
      groupStructure: '',
      iconName: 'configurator/shelf/parts-backwalls',
      labelMode: 'color_backwalls',
      selectionMode: 'multi',
      resetSelection: true,
      subTabsName: [],
      text: 'Backwalls',
    },
    colorsFeet: {
      zoomMax: 1,
      camera: {
        low: { angle: 0, vAngle: 0 },
        high: { angle: 0, vAngle: 0 },
      },
      group: 'color',
      groupStructure: '',
      iconName: 'configurator/shelf/parts-legs',
      labelMode: 'color_legs',
      selectionMode: 'all',
      resetSelection: true,
      subTabsName: [],
      text: 'Feet',
    },
    colorsHanging: {
      zoomMax: 1,
      camera: {
        low: { angle: 0, vAngle: 0 },
        high: { angle: 0, vAngle: 0 },
      },
      group: 'color',
      groupStructure: '',
      iconName: 'configurator/hanger-color.svg',
      labelMode: 'color_hanging',
      selectionMode: 'multi',
      resetSelection: true,
      subTabsName: [],
      text: 'Hanger',
    },
    colorsSocle: {
      zoomMax: 1,
      camera: {
        low: { angle: 0, vAngle: 0 },
        high: { angle: 0, vAngle: 0 },
      },
      group: 'color',
      groupStructure: '',
      iconName: 'configurator/shelf/parts-legs',
      labelMode: 'color_legs',
      selectionMode: 'multi',
      resetSelection: true,
      subTabsName: [],
      text: 'Feet',
    },
    colorsWhole: {
      zoomMax: 1.8,
      camera: {
        low: { angle: 0, vAngle: 0 },
        high: { angle: 0, vAngle: 0 },
      },
      group: 'color',
      groupStructure: '',
      iconName: 'configurator/shelf/parts-whole',
      labelMode: 'whole',
      selectionMode: 'all',
      resetSelection: false,
      subTabsName: [],
      text: 'Whole',
    },
    colors: {
      zoomMax: 1.8,
      camera: {
        low: { angle: 0, vAngle: 0 },
        high: { angle: 0, vAngle: 0 },
      },
      group: '',
      groupStructure: '',
      iconName: '',
      labelMode: '',
      selectionMode: 'none',
      resetSelection: true,
      subTabsName: [
        'colors',
        'colorsWhole',
        'colorsBoards',
        'colorsMetalBars',
        'colorsWalls',
        'colorsFronts',
        'colorsHandles',
        'colorsBackwalls',
        'colorsFeet',
        'colorsHanging',
        'colorsSocle',
      ],
      defaultSubTabName: 'colors',
      URLTabName: 'colors',
      text: '',
    },
    colorsMobile: {
      zoomMax: 1.8,
      camera: {
        low: { angle: 0, vAngle: 0 },
        high: { angle: 0, vAngle: 0 },
      },
      group: '',
      groupStructure: '',
      iconName: '',
      labelMode: '',
      selectionMode: 'none',
      resetSelection: true,
      subTabsName: [
        'colorsWhole',
        'colorsBoards',
        'colorsMetalBars',
        'colorsWalls',
        'colorsFronts',
        'colorsHandles',
        'colorsBackwalls',
        'colorsFeet',
        'colorsHanging',
        'colorsSocle',
      ],
      defaultSubTabName: 'colorsWhole',
      URLTabName: 'colors',
      text: '',
    },
    review: {
      zoomMax: 1,
      subTabsName: [],
      text: '',
      iconName: '',
      selectionMode: 'single',
      resetSelection: true,
      group: '',
      groupStructure: '',
      camera: {
        low: { angle: 30, vAngle: -20 },
        high: { angle: 30, vAngle: 0 },
      },
      labelMode: '',
    },
    default: {
      zoomMax: 1.8,
      camera: {
        low: { angle: 0, vAngle: 0 },
        high: { angle: 0, vAngle: 0 },
      },
      group: 'color',
      groupStructure: '',
      iconName: '',
      labelMode: 'whole',
      selectionMode: 'all',
      resetSelection: true,
      subTabsName: [
        'width',
        'height',
        'depth',
        'styles',
        'dimensions-legs',
        'legs',
        'fronts',
        'backwalls',
        'boards',
        'colorsMobile',
        'baseboard-cuts',
      ],
      text: '',
    },
  },
  notifications: {},
  defaultDimensions: {},
  legsSpecsIgnoreTypes: [
    'platform_plate',
    'platform_profile',
    'socle_profile',
    'square_support',
    'support',
  ],
  // for manual-api JIRA ticket MYCS-7897
  legTypeMapping: {
    socle: 'LYFT',
    slanted: 'NORDYC',
    straight: 'CYRCLE',
    square: 'CLASSYC',
    roll: 'SPYN',
    flat: 'SLYM',
    narrow: 'SHYFT',
    frame: 'SYMPLE',
    corner: 'LYNK',
    hairpin: 'PYN',
  },
  // components, which are define the primary colour
  // and weights to define priority (default weight is 1)
  primarySections: {
    wall: 1,
    legs_47: 1,
    door: 1,
    backwall: 1,
    board: 1,
    legs: 1,
    drawer_front: 1,
    wall_extension: 1,
  },
};

export interface ShelfFurnitureConfig extends FurnitureConfig {
  backwallPaddign: number;
  baseboardCuts: any;
  baseBoardCutsFeature: number;
  boardHeight: number;
  boardTypeBackwall: string;
  boardTypeBackwallTop: string;
  boardTypeBackwallBehindFront: string;
  boardTypeSplittingBackwallBehindFront: string;
  boardTypeBehindFront: string;
  boardTypeLegs: string;
  boardTypeNormal: string;
  colors: { [index in ShelfColorCategory]: string[] };
  defaultColor: string;
  depthDimensions: any;
  dimensionsFilters: string[];
  doorFrontType: string;
  doorWidth: number;
  drawerBoxHeights: { [key: number]: number };
  drawerFrontType: string;
  glassFrontHingesPosition: { [key: number]: number[] };
  firstDoorPadding: number;
  frontBoardGap: number;
  frontPosition: number;
  frontWidth: number;
  frontsGap: number;
  handleHeight: number;
  heightWarningLimit: number;
  heightWarningToAdd: number;
  heightTypes: { [key: string]: { [index: number]: number } };
  hingeSize: number;
  laminateMapping: { [key: string]: string };
  lacquerMapping: { [key: string]: string };
  legTypeMapping: {
    socle: string;
    slanted: string;
    straight: string;
    square: string;
    roll: string;
    flat: string;
    narrow: string;
    frame: string;
    corner: string;
    hairpin: string;
  };
  legTypes: any;
  legsSpecsIgnoreTypes: string[];
  lengthCombos: any[];
  maxColumnDepth: number;
  maxColumnWidth: number;
  maxColumnsNumber: number;
  maxShelfWidth: number;
  metalBarLengthMapping: { [key: number]: number };
  metalLegsMapping: { [key: string]: string };
  minBoardPosition: number;
  minColumnDepth: number;
  minColumnWidth: number;
  minViewFromTopHeight: number;
  narrowPlatformProfilePattern: PlatformPattern;
  numberSections: { [key: string]: any };
  platformPlatePattern: PlatformPattern;
  reinforcedBoardSuffix: string;
  secondDoorPadding: number;
  sectionHeight: number;
  socleProfilePattern: PlatformPattern;
  validColor: {
    board: string[];
    handle: string[];
    front: string[];
    backwall: string[];
    wall: string[];
    feet: {
      socle: string[];
      slanted: string[];
      straight: string[];
      square: string[];
      roll: string[];
      flat: string[];
      narrow: string[];
      corner: string[];
      frame: string[];
      hairpin: string[];
    };
    whole: string[];
  };
  validColumnHeightInSections: number[];
  validHeightColumn: number[];

  wallBoardGap: number;
  wallHeightFoSplit: { [key: number]: number[] };
  wallTypeOuter: string;
  wallTypeInner: string;

  XOffsetGlassHandle: number;
  YOffsetGlassHandle: number;
  ZOffsetGlassHandle: number;
  wallWidth: number;
  widePlatformProfilePattern: PlatformPattern;
  primarySections: {
    [key: string]: number;
  };
}
export interface ShelfFrontOption extends Option {
  value: {
    type: 'door' | 'drawer';
    height: number;
  };
}

export type ShelfColorCategory =
  | 'wood'
  | 'lacquered'
  | 'laminate'
  | 'aluminum'
  | 'legsRoll'
  | 'glass';
export type PlatformPattern = {
  section: string;
  type: string;
  length?: number;
};

export default shelfConfig;
