import { useEffect, useState } from 'react';
import classNames from 'classnames';

import { useLocale } from 'mycs/shared/state/LocaleContext';
import Collapsible from 'mycs/shared/components/Collapsible/Collapsible';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';
import Icon from 'mycs/shared/components/Icon/Icon';
import SafeText from 'mycs/shared/components/SafeText/SafeText';
import ParagraphClarifyingText from 'mycs/shared/components/ParagraphClarifyingText/ParagraphClarifyingText';

import styles from './CollapsingPanel.scss';

type Props = {
  className?: string;
  defaultCollapsed?: boolean;
  isAutoCollapsible?: boolean;
  onExpand?: () => void;
  onCollapse?: () => void;
  contentClassName?: string;
  headClassName?: string;
  headlineClassName?: string;
  headLabel?: string;
  headline?: string;
  clarifyingText?: string;
  content: React.ReactNode;
  iconName?: string;
  iconClassName?: string;
  isCompactLayout?: boolean;
  isCenterLayout?: boolean;
  isLightLayout?: boolean;
  isInfoCollapsingPanel?: boolean;
  isTransparent?: boolean;
  isMustRead?: boolean;
  openClassName?: string;
  isConfiguratorLayout?: boolean;
  clickOutsideWhiteList?: string[];
  disableClickOutside?: boolean;
  isWithoutSideMargins?: boolean;
  isBlackText?: boolean;
};

export default function CollapsingPanel({
  className,
  content,
  contentClassName,
  defaultCollapsed = true,
  headClassName,
  headLabel,
  headline,
  headlineClassName,
  clarifyingText,
  iconClassName = styles.iconContainer,
  iconName = 'general/close',
  isAutoCollapsible,
  isCenterLayout = false,
  isCompactLayout = false,
  isInfoCollapsingPanel = false,
  isLightLayout = false,
  isMustRead: isMustReadProp,
  isTransparent = false,
  onCollapse = () => null,
  onExpand = () => null,
  openClassName,
  isConfiguratorLayout = false,
  clickOutsideWhiteList,
  disableClickOutside = false,
  isWithoutSideMargins = false,
  isBlackText = false,
}: Props) {
  const { locale } = useLocale();
  const [collapsed, setCollapsed] = useState(defaultCollapsed);
  const isMustRead = isMustReadProp || !!clarifyingText;

  useEffect(() => {
    if (defaultCollapsed !== collapsed) {
      setCollapsed(defaultCollapsed);
    }
  }, [defaultCollapsed]);

  /**
   * changes the icon from + to x
   */
  const handleOnExpand = () => {
    setCollapsed(false);
    onExpand?.();
  };
  /**
   * changes the icon from x to +
   */
  const handlOnCollapse = () => {
    setCollapsed(true);
    onCollapse?.();
  };

  const containerClass = classNames(styles.collapsingPanel, className, {
    [styles.open]: !collapsed,
    [styles.small]: isCompactLayout,
    [styles.center]: isCenterLayout,
    [styles.white]: isLightLayout,
    [styles.infoCollapsingPanel]: isInfoCollapsingPanel,
    [styles.transparent]: isTransparent,
    [openClassName!]: !collapsed,
    [styles.configuratorLayout]: isConfiguratorLayout,
    [styles.withoutSideMargins]: isWithoutSideMargins,
    [styles.blackText]: isBlackText,
  });

  const headerContent = (
    <div
      className={classNames(styles.headlineButton, headClassName)}
      data-testid="toggle-collapsing-panel"
    >
      {isInfoCollapsingPanel && (
        <Icon
          iconName="configurator/info"
          isInline
          className={styles.infoIcon}
        />
      )}

      <h3 className={classNames(styles.headline, headlineClassName)}>
        {I18nUtils.localize(locale, headline)}
        {isMustRead && (
          <span className={styles.panelLabel}>
            {I18nUtils.localize(locale, 'must read')}
          </span>
        )}
      </h3>

      <div className={classNames(styles.iconContainer, iconClassName)}>
        <Icon iconName={iconName} />
      </div>
    </div>
  );

  const wrappedContent = (
    <div className={classNames(styles.content, contentClassName)}>
      {clarifyingText && (
        <div className={styles.clarifyingTextContainer}>
          <ParagraphClarifyingText clarifyingText={clarifyingText} />
        </div>
      )}
      {typeof content === 'string' ? <SafeText content={content} /> : content}
    </div>
  );

  return (
    <div className={containerClass} data-testid="collapsing-panel-container">
      <Collapsible
        title={headLabel || headline}
        header={headerContent}
        content={wrappedContent}
        onExpand={handleOnExpand}
        onCollapse={handlOnCollapse}
        defaultCollapsed={defaultCollapsed}
        isAutoCollapsible={isAutoCollapsible}
        clickOutsideWhiteList={clickOutsideWhiteList}
        disableClickOutside={disableClickOutside}
      />
    </div>
  );
}
